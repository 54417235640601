import React from "react";
import { Link } from "react-scroll";
import "./styles.css";
import { Col, Row } from "antd";

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer">
        <Row>
          <Col lg={8} md={24} sm={24} xs={24} className="footer-left">
            <div className="informations-container">
              <div className="logo_container">
                <img
                  src={require("../../images/logo.png")}
                  alt="International Media Network"
                />
              </div>
              <div className="informations">
                <h3>International Media Network</h3>
                <p>45, 49 osborne Road 45</p>
                <p>Kingsdon</p>
                <p>TA11 1AU</p>
              </div>
            </div>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} className="footer-center">
            <div>
              <h3>Links</h3>
              <nav className="nav-footer">
                <Link
                  activeClass="active"
                  to="about-us"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  About us
                </Link>
                <Link
                  activeClass="active"
                  to="services"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  Services
                </Link>
                <Link
                  activeClass="active"
                  to="why-us"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  Why us?
                </Link>
                <Link
                  activeClass="active"
                  to="our-commitment"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  Our Commitment
                </Link>
                <Link
                  activeClass="active"
                  to="contact-us"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  Contact us
                </Link>
              </nav>
            </div>
          </Col>
          <Col lg={8} md={24} sm={24} xs={24} className="footer-right">
            Site created by the company{" "}
            <a href="https://k-dev.fr" target="_blank" rel="noreferrer">
              K-DEV
            </a>
          </Col>
        </Row>
      </div>
    </footer>
  );
}

export default Footer;
