import React from "react";
import "./styles.css";

function AboutUs() {
  return (
    <div className="about-us-container">
      <div className="about-us" id="about-us">
        <h2>About Us</h2>
        <div>
          At International Media Network, we're dedicated to helping businesses
          expand their reach across borders. With a focus on facilitating global
          growth, we offer a diverse range of services tailored to meet the
          evolving needs of modern enterprises. From crafting captivating
          multimedia marketing campaigns to fostering meaningful connections
          through community management, optimizing your online presence with
          cutting-edge SEO strategies, developing bespoke social media marketing
          tactics, conducting insightful market analysis, and creating visually
          stunning websites, we provide a comprehensive suite of solutions
          designed to empower French enterprises seeking to establish a foothold
          in the United Kingdom market. Our mission is to equip businesses with
          the necessary tools, strategies, and insights to navigate the
          complexities of international expansion successfully. By leveraging
          our expertise and industry knowledge, we enable our clients to seize
          new opportunities, overcome challenges, and achieve sustainable growth
          on a global scale. Partner with International Media Network and embark
          on a transformative journey towards cross-border success.
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
