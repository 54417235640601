import React from "react";
import "./styles.css";
import { Card, Col, Row } from "antd";

function WhyUs() {
  return (
    <div className="why-us-container">
      <div className="why-us" id="why-us">
        <h2>Why Us?</h2>
        <div>
          <Row gutter={[20, 20]}>
            <Col xl={6} md={12}>
              <div className="why-us-option">
                <Card
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <h3>Global Perspective</h3>
                  <img
                    src={require("../../images/why-us/global-perspective.png")}
                    alt="global-perspective"
                  />
                  <p>
                    With a focus on helping French businesses penetrate the UK
                    market, we offer a unique perspective and tailored solutions
                    to drive your international growth.
                  </p>
                </Card>
              </div>
            </Col>
            <Col xl={6} md={12}>
              <div className="why-us-option">
                <Card
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <h3>Expertise and Innovation</h3>
                  <img
                    src={require("../../images/why-us/expertise-and-innovation.jpeg")}
                    alt="expertise-and-innovation"
                  />
                  <p>
                    Our team of seasoned professionals combines expertise with
                    innovative approaches to deliver cutting-edge marketing
                    solutions that yield tangible results.
                  </p>
                </Card>
              </div>
            </Col>
            <Col xl={6} md={12}>
              <div className="why-us-option">
                <Card
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <h3>Customized Strategies</h3>
                  <img
                    src={require("../../images/why-us/customized-strategies.jpeg")}
                    alt="customized-strategies"
                  />
                  <p>
                    We understand that every business is unique. That's why we
                    work closely with you to develop customized strategies that
                    align with your goals and objectives.
                  </p>
                </Card>
              </div>
            </Col>
            <Col xl={6} md={12}>
              <div className="why-us-option">
                <Card
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <h3>Measurable Impact</h3>
                  <img
                    src={require("../../images/why-us/measurable-impact.webp")}
                    alt="measurable-impact"
                  />
                  <p>
                    Transparency and accountability are at the core of our
                    operations. We provide regular reports and analytics to
                    track the performance of our campaigns and ensure maximum
                    ROI.
                  </p>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
