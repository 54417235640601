import React from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import "./styles.css";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const Contact = () => (
  <div className="contact-container">
    <div className="contact" id="contact-us">
      <h2>Contact Us</h2>
      <Card>
        <Row gutter={20}>
          <Col lg={12} md={24}>
            <Form {...formItemLayout} style={{ maxWidth: 600 }}>
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please fill your name!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please fill your email address!",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label="Phone number"
                name="phone"
                rules={[
                  { required: true, message: "Please fill your phone number!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Your message"
                name="message"
                rules={[
                  { required: true, message: "Please fill your message!" },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Send message
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col lg={12} md={24}>
            <div className="informations-container">
              <div className="logo_container">
                <img
                  src={require("../../images/logo.png")}
                  alt="International Media Network"
                />
              </div>
              <div className="informations">
                <h3>International Media Network</h3>
                <p>45, 49 osborne Road 45</p>
                <p>Kingsdon</p>
                <p>TA11 1AU</p>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  </div>
);

export default Contact;
