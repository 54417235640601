import React, { useState } from "react";
import { Link } from "react-scroll";
import "./styles.css";
import { MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";

function Header() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <header>
      <div className="logo_container">
        <img
          src={require("../../images/logo.png")}
          alt="International Media Network"
        />
      </div>
      <div className="header-nav">
        <nav className="nav">
          <Link
            activeClass="active"
            to="about-us"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            About us
          </Link>
          <Link
            activeClass="active"
            to="services"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            Services
          </Link>
          <Link
            activeClass="active"
            to="why-us"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            Why us?
          </Link>
          <Link
            activeClass="active"
            to="our-commitment"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            Our Commitment
          </Link>
          <Link
            activeClass="active"
            to="contact-us"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            Contact us
          </Link>
        </nav>
      </div>
      <div className="header-nav-mobile">
        <div onClick={showDrawer} className="drawer-button-container">
          <MenuOutlined />
        </div>
        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          open={open}
          key="left"
          className="drawer"
        >
          <div className="drawer-logo">
            <img
              src={require("../../images/logo.png")}
              alt="International Media Network"
            />
          </div>
          <Link
            activeClass="active"
            to="about-us"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={onClose}
          >
            About us
          </Link>
          <Link
            activeClass="active"
            to="services"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={onClose}
          >
            Services
          </Link>
          <Link
            activeClass="active"
            to="why-us"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={onClose}
          >
            Why us?
          </Link>
          <Link
            activeClass="active"
            to="our-commitment"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={onClose}
          >
            Our Commitment
          </Link>
          <Link
            activeClass="active"
            to="contact-us"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onClick={onClose}
          >
            Contact us
          </Link>
        </Drawer>
      </div>
    </header>
  );
}

export default Header;
