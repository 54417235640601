import React from "react";
import "./styles.css";

function TopPage() {
  return (
    <div className="top-page">
      <div className="top-page-hover">
        <h1>Welcome to International Media Network</h1>
        <h2>Empowering Your Global Marketing Reach</h2>
      </div>
    </div>
  );
}

export default TopPage;
