import React from "react";
import "./styles.css";
import { Card, Col, Row } from "antd";

function Services() {
  return (
    <div className="services-container">
      <div className="services" id="services">
        <h2>Our Services</h2>
        <Row gutter={[20, 20]}>
          <Col xl={8} md={12}>
            <div className="service">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <h3>Multimedia Marketing Campaigns</h3>
                <img
                  src={require("../../images/services/multimedia-marketing-campaigns.webp")}
                  alt="multimedia-marketing-campaigns"
                />
                <p>
                  We craft dynamic multimedia campaigns tailored to your target
                  audience, ensuring maximum impact across various channels.
                </p>
              </Card>
            </div>
          </Col>
          <Col xl={8} md={12}>
            <div className="service">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <h3>Community Management</h3>
                <img
                  src={require("../../images/services/community-management.jpeg")}
                  alt="community-management"
                />
                <p>
                  Engage and grow your online community with our expert
                  community management services, fostering meaningful
                  connections and driving brand loyalty.
                </p>
              </Card>
            </div>
          </Col>
          <Col xl={8} md={12}>
            <div className="service">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <h3>SEO (Search Engine Optimization)</h3>
                <img
                  src={require("../../images/services/SEO.webp")}
                  alt="SEO"
                />
                <p>
                  Improve your online visibility and drive organic traffic to
                  your website with our SEO strategies, tailored to boost your
                  rankings on search engines.
                </p>
              </Card>
            </div>
          </Col>
          <Col xl={8} md={12}>
            <div className="service">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <h3>Social Media Marketing Strategy</h3>
                <img
                  src={require("../../images/services/social-media-marketing-strategy.jpeg")}
                  alt="social-media-marketing-strategy.jpeg"
                />
                <p>
                  Our team develops comprehensive commercial and technical
                  social media marketing strategies, leveraging the power of
                  social platforms to enhance your brand presence and drive
                  sales.
                </p>
              </Card>
            </div>
          </Col>
          <Col xl={8} md={12}>
            <div className="service">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <h3>Market Analysis and Research</h3>
                <img
                  src={require("../../images/services/market-analysis-and-research.jpeg")}
                  alt="market-analysis-and-research"
                />
                <p>
                  Gain valuable insights into your target market with our
                  in-depth analysis and research services, allowing you to make
                  informed decisions and stay ahead of the competition.
                </p>
              </Card>
            </div>
          </Col>
          <Col xl={8} md={12}>
            <div className="service">
              <Card
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <h3>Web Design</h3>
                <img
                  src={require("../../images/services/web-design.jpeg")}
                  alt="web-design"
                />
                <p>
                  Enhance your online presence with visually stunning and
                  user-friendly websites designed to captivate your audience and
                  drive conversions.
                </p>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Services;
