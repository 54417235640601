import React from "react";
import "./styles.css";
import { Card, Carousel, Col, Row } from "antd";

function Engagement() {
  return (
    <div className="engagement-container">
      <div className="engagement" id="our-commitment">
        <h2>Our Commitment to Excellence</h2>
        <Row gutter={[20, 20]}>
          <Col xl={12} lg={24}>
            <Card
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Row gutter={20}>
                <Col md={12} sm={24}>
                  <div className="engagement-img-container">
                    <img
                      src={require("../../images/Accompagner-ses-collaborateurs-vers-excellence-F.jpg")}
                      alt="engagement"
                    />
                  </div>
                </Col>
                <Col md={12} sm={24}>
                  <div className="engagement-text">
                    At International Media Network, excellence is not just a
                    goal; it's our standard. We are committed to delivering
                    exceptional service and results that exceed expectations.
                    From the initial consultation to the execution of your
                    marketing strategies, we prioritize communication,
                    collaboration, and quality every step of the way. With our
                    dedicated team by your side, you can trust that your
                    business objectives will be met with professionalism,
                    creativity, and unwavering dedication. Partner with us and
                    embark on a journey towards unparalleled success in the UK
                    market and beyond.
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xl={12} lg={24}>
            <Card
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Carousel
                autoplay
                style={{
                  height: "100%",
                }}
              >
                <div
                  className="testimony"
                  style={{
                    height: "100%",
                  }}
                >
                  <div className="testimony-content">
                    <img
                      src={require("../../images/person/person.avif")}
                      alt="person"
                    />
                    <h3>David Miller</h3>
                    <p>
                      Outstanding Service! International Media Network provided
                      exceptional support in expanding my business into the UK
                      market. Their tailored marketing strategy boosted my
                      brand's visibility and drove impressive results. Highly
                      recommended
                    </p>
                  </div>
                </div>
                <div
                  className="testimony"
                  style={{
                    height: "100%",
                  }}
                >
                  <div className="testimony-content">
                    <img
                      src={require("../../images/person/person2.png")}
                      alt="person"
                    />
                    <h3>Ryan Thompson</h3>
                    <p>
                      Top-notch service! International Media Network helped my
                      business break into the UK market seamlessly. Their
                      expertise in marketing strategies and dedication to client
                      success truly impressed me. Thanks to their efforts, my
                      business is thriving. Highly recommend!
                    </p>
                  </div>
                </div>
                <div
                  className="testimony"
                  style={{
                    height: "100%",
                  }}
                >
                  <div className="testimony-content">
                    <img
                      src={require("../../images/person/person3.webp")}
                      alt="person"
                    />
                    <h3>Jessica Parker</h3>
                    <p>
                      Excellent experience! International Media Network went
                      above and beyond to elevate my business in the UK market.
                      Their strategic approach and attention to detail resulted
                      in remarkable growth. Couldn't be happier with the
                      results!
                    </p>
                  </div>
                </div>
              </Carousel>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Engagement;
