import "./App.css";
import {
  AboutUs,
  Contact,
  Engagement,
  Footer,
  Header,
  Services,
  TopPage,
  WhyUs,
} from "./components";

function App() {
  return (
    <div>
      <Header />
      <main>
        <TopPage />
        <AboutUs />
        <Services />
        <WhyUs />
        <Engagement />
        <Contact />
        <Footer />
      </main>
    </div>
  );
}

export default App;
